<template>
  <v-card flat id="add-apiary-card" class="my-card">
    <v-row>
      <v-col cols="12" sm="6" md>
        <v-text-field
          :label="$t('name')"
          v-model="newApiary.name"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          class="my-text-field"
        ></v-text-field>
      </v-col>
      <v-col id="add-location-container" cols="12" sm="6" md>
        <v-text-field
          :label="$t('location')"
          v-model="newApiary.description"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          :prepend-inner-icon="search_icon"
          class="my-text-field"
          @click:append="doGeocode(newApiary.description)"
          @keydown.enter.exact.prevent="doGeocode(newApiary.description)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md class="hidden-sm-and-down">
        <v-select
          :items="$t('apiaries.functions')"
          item-text="viewValue"
          item-value="value"
          :label="$t('function')"
          :placeholder="$t('function')"
          v-model="newApiary.apiaryFunction"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          class="my-select-box"
          append-icon="mdi-chevron-down"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template slot="item" slot-scope="data">
            <span class="apiary-list-item">{{data.item.viewValue}}</span>
          </template>
          <template slot="selection" slot-scope="data">
            <span class="apiary-list-item">{{data.item.viewValue}}</span>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12">
        <GmapMap
          :center="center"
          :zoom="zoom"
          :map-type-id="mapType"
          style="width: auto; height: 280px"
          :options="mapOptions"
        >
          <GmapMarker
            v-if="newApiary.lat"
            :position="{ lat: newApiary.lat, lng: newApiary.lng }"
            :clickable="true"
            :draggable="true"
            @click="
              center = { lat: newApiary.lat, lng: newApiary.lng };
              zoom = 15;
            "
            @drag="updateCoordinates"
          ></GmapMarker>
        </GmapMap>
      </v-col>
    </v-row>

    <v-card-actions class="my-card-actions mt-5">
      <v-row>
        <v-col cols="12" sm="6" offset-sm="6">
          <v-btn
            height="54px"
            depressed
            block
            dark
            class="my-btn save-btn"
            @click="saveApiaryAction"
            :disabled="!isNewApiaryValid"
          >
            {{$t("create")}}
            <v-spacer></v-spacer>
            <v-icon>{{check_icon}}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'AddApiaryCard',
    computed: {
      isNewApiaryValid() {
        return this.newApiary.name != '' &&
          this.newApiary.description != null &&
          this.newApiary.description != '' 
      }
    },
    data: () => ({
      zoom: 12,
      mapType: "terrain",
      mapOptions: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUI: false,
      },
      center: {
        lat: 38.2749497,
        lng: 23.8102717
      },
      newApiary: {
        name: '',
        lat: null,
        lng: null,
        apiaryFunction: '',
        description: null,
        forage: '',
        hiveCount: null
      },
      search_icon: "$vuetify.icons.search_icon",
      check_icon: "$vuetify.icons.check_icon",
    }),
    mounted() {
      this.geolocate();
    },
    methods: {
      saveApiaryAction() {
        this.$emit('save', this.newApiary);
      },
      cancelAction() {
        this.newApiary = {
          name: '',
          lat: null,
          lng: null,
          apiaryFunction: '',
          description: null,
          forage: '',
          hiveCount: null
        };
        this.geolocate();
        this.$emit('close');
      },
      setPlace(place) {
        this.newApiary.description = place;
      },
      geolocate() {
        navigator.geolocation.getCurrentPosition(position => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.newApiary.lat = position.coords.latitude;
          this.newApiary.lng = position.coords.longitude;
        });
      },
      doGeocode(address) {
        this.address = true;

        let coordinates;

        this.$gmapApiPromiseLazy().then(() => {
          const geocoder = new google.maps.Geocoder();

          geocoder.geocode(
            {
              address: address,
              componentRestrictions: {
                country: "Greece",
              },
            },
            function (results, status) {
              if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                address = results[0].formatted_address;
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();
                this.newApiary.description = address;
                this.newApiary.lat = latitude;
                this.newApiary.lng = longitude;
                this.center = { lat: latitude, lng: longitude };
              } else {
                this.newApiary.description = address;
                this.newApiary.lat = 38.2749497;
                this.newApiary.lng = 23.8102717;
                this.center = { lat: this.newApiary.lat, lng: this.newApiary.lng };

                return;
              }
            }.bind(this)
          );
        });
      },
      updateCoordinates(location) {
        this.newApiary.lat = location.latLng.lat();
        this.newApiary.lng = location.latLng.lng();
        this.$gmapApiPromiseLazy().then(() => {
          const geocoder = new google.maps.Geocoder();

          // Geocode the address
          geocoder.geocode(
            {
              location: { lat: this.newApiary.lat, lng: this.newApiary.lng },
            },
            function (results, status) {
              if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                this.newApiary.description = results[0].formatted_address;
              } else {
                return;
              }
            }.bind(this)
          );
        });
      },
    }
  }
</script>

<style type="text/css">
  #add-apiary-card {
    padding-top: 40px;
    position: relative;
  }

  #add-apiary-card.my-card .my-select-box {
    padding: 0;
  }

  #add-apiary-card.my-card .my-select-box .v-label,
  #add-apiary-card.my-card .my-select-box input,
  #add-apiary-card.my-card .my-text-field .v-label,
  #add-apiary-card.my-card .my-text-field input {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.75px;
  }

  .apiary-list-item {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #262338;
  }

  #add-location-container .my-text-field .v-input__icon {
    margin: 0 10px 0 10px;
  }

  @media screen and (max-width: 600px) {
    #add-apiary-modal.dialog-container-center {
      width: 100%;
      left: 0;
      padding: 0;
    }

    #add-apiary-card.my-card {
      padding: 40px 30px 70px;
      border-radius: 0;
    }
  }
</style>
