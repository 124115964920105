<template>
  <div id="apiary-page">
    <v-row class="pa-sm-4 pa-md-6 pa-xl-8">
      <v-col cols="12" sm="12" md="6">
        <ApiariesCard
          @open-add-apiary-modal="openAddApiaryModal"
          @view-apiary="viewApiary"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <EditApiaryCard
          v-if="!isEmptyEditApiary"
          @save="updateApiaryAction"
          @delete-apiary="confirmDelete"
          :center="mapCenter"
        />
        <AddApiaryCard
          v-else-if="isEmptyEditApiary && showAddApiaryCard"
          @save="createApiary"
        />
      </v-col>
    </v-row>

    <v-overlay :value="showLoader">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <ConfirmDeleteModal :visible="showConfirmDeleteModal"
      @close="showConfirmDeleteModal = false"
      @proceed="deleteApiaryAction"
    />
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import axios from 'axios';
  import moment from 'moment';

  import ApiariesCard from '@/components/Apiaries/ApiariesCard.vue';
  import AddApiaryCard from '@/components/Apiaries/AddApiaryCard.vue';
  import EditApiaryCard from '@/components/Apiaries/EditApiaryCard.vue';
  import ConfirmDeleteModal from '@/components/Apiaries/ConfirmDeleteModal.vue';

  export default {
    name: 'Apiaries',
    components: {
      ApiariesCard,
      AddApiaryCard,
      EditApiaryCard,
      ConfirmDeleteModal
    },
    computed: {
      ...mapGetters([
        "inventory",
        "selectedApiary",
        "editApiary",
        "apiariesWithRatio",
        "inspectionDates"
      ]),
      isEmptyEditApiary() {
        return this.editApiary == null;
      }
    },
    data: () => ({
      showLoader: false,
      showAddApiaryCard: false,
      showConfirmDeleteModal: false,
      publicPath: process.env.BASE_URL,
      mapCenter: null
    }),
    mounted() {
      this.setIsHiddenApiaryButton(true);
    },
    methods: {
      ...mapActions([
        "addApiary",
        "updateApiary",
        "updateSelectedApiary",
        "setIsHiddenApiaryButton",
        "deleteApiary",
        "setEditApiary",
        "getInventory",
        "setApiariesWithRatio",
        "getInspectionDates",
        "setLatestInspectionDate",
        "setSelectedInspectionDate"
      ]),
      getApiaryLastInspection(apiaryId, last_group_id) {
        let url = `${process.env.VUE_APP_BASE_URL}/api/v1/hivesWithStatus`;
        let payload = {
          'apiaryId': apiaryId,
          'groupId': last_group_id
        };
        return new Promise((resolve, reject) => {
          axios({
            method: 'get',
            url: url,
            params: {
              'apiary_id': payload.apiaryId,
              'group_id': payload.groupId
            }
          })
          .then(res => {
            resolve(res.data.result);
          })
          .catch(er => {
            console.log(er);
            reject(er);
          });
        });
      },
      openAddApiaryModal() {
        this.setEditApiary(null).then(() => {
          this.showAddApiaryCard = true;
        });
      },
      closeAddApiaryModal() {
        this.showAddApiaryModal = false;
        document.body.classList.remove("modal-open");
      },
      createApiary(apiary) {
        this.showLoader = true;
        this.addApiary(apiary).then(() => {
          this.$gtag.event(
            'Save new apiary',
            {
              'event_category': 'Apiaries'
            }
          );

          this.getInventory().then(() => {
            this.showLoader = false;
            this.showAddApiaryCard = false;
          });
        })
        .catch(() => {
          this.showLoader = false;
          this.showAddApiaryCard = false;
        });
      },
      updateApiaryAction(editApiary) {
        this.showLoader = true;
        this.updateApiary(editApiary).then(() => {
          this.$gtag.event(
            'Edit apiary',
            {
              'event_category': 'Apiaries'
            }
          );
          this.showLoader = false;
        });
      },
      confirmDelete() {
        this.showConfirmDeleteModal = true;
      },
      deleteApiaryAction() {
        this.showLoader = true;
        this.showConfirmDeleteModal = false;

        this.deleteApiary(this.editApiary).then(() => {
          this.setEditApiary(null);
          this.$gtag.event(
            'Delete apiary',
            {
              'event_category': 'Apiaries'
            }
          );

          this.getInventory().then(() => {
            if (this.inventory.length != 0) {
              this.updateSelectedApiary(this.inventory[0]).then(() => {
                this.getInspectionDates(this.selectedApiary.id).then(() => {
                  if (this.inspectionDates.length != 0) {
                    let latestInspection = {
                      date: moment(this.selectedApiary.last_group_date).format("YYYY/MM/DD"),
                      id: this.selectedApiary.last_group_id
                    };
                    this.setLatestInspectionDate(latestInspection);
                    this.setSelectedInspectionDate(latestInspection.date);
                  } else {
                    this.setLatestInspectionDate({});
                    this.setSelectedInspectionDate('');
                  }
                  this.showLoader = false;
                });
              });
            }
          }).catch((er) => {
            this.loader = false;
          });
        }).catch((err) => {
          console.log(err);
        });
      },
      viewApiary(itemCoords) {
        this.mapCenter = itemCoords;
        this.showAddApiaryCard = false;
      }
    }
  }
</script>

<style type="text/css">
  #apiary-page {
    position: relative;
  }
  
  #apiary-page .add-apiary-btn {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #14142B;
    text-transform: capitalize;
  }

  #apiary-page .apiary-tabs .my-tab.v-tab--disabled {
    opacity: 1;
  }

  @media screen and (max-width: 1280px) {
    #apiary-page .add-apiary-btn {
      right: 50px;
    }
  }

  @media screen and (max-width: 1100px) {
    #apiary-page .add-apiary-btn {
      right: 0;
    }
  }

  @media screen and (max-width: 600px) {
    #apiary-page .add-apiary-btn {
      position: unset;
    }
  }
</style>