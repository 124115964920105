<template>
  <v-card flat id="edit-apiary-card" class="my-card">
    <v-row>
      <v-col cols="12" sm="6" md>
        <v-text-field
          :label="$t('name')"
          v-model="name"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          class="my-text-field"
        ></v-text-field>
      </v-col>
      <v-col id="edit-location-container" cols="12" sm="6" md>
        <v-text-field
          :label="$t('location')"
          v-model="description"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          :prepend-inner-icon="search_icon"
          class="my-text-field"
          @click:append="doGeocode(description)"
          @keydown.enter.exact.prevent="doGeocode(description)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md>
        <v-select
          :items="$t('apiaries.functions')"
          item-text="viewValue"
          item-value="value"
          :label="$t('function')"
          :placeholder="$t('function')"
          v-model="apiaryFunction"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          class="my-select-box"
          append-icon="mdi-chevron-down"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template slot="item" slot-scope="data">
            <span class="apiary-list-item">{{data.item.viewValue}}</span>
          </template>
          <template slot="selection" slot-scope="data">
            <span class="apiary-list-item">{{data.item.viewValue}}</span>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12">
        <GmapMap
          :center="center"
          :zoom="zoom"
          :map-type-id="mapType"
          style="width: auto; height: 280px"
          :options="mapOptions"
        >
          <GmapMarker
            v-if="editApiary.lat"
            :position="{ lat: editApiary.lat, lng: editApiary.lng }"
            :clickable="true"
            :draggable="true"
            @click="zoom = 15;"
            @drag="updateCoordinates"
            @mouseover="showInfoWindow = true"
            @mouseout="showInfoWindow = false"
          >
            <gmap-info-window :opened="showInfoWindow">
              {{editApiary.description}}
            </gmap-info-window>
          </GmapMarker>
        </GmapMap>
      </v-col>
    </v-row>

    <v-card-actions class="my-card-actions mt-5">
      <v-row>
        <v-col cols="12" sm>
          <v-btn
            height="54px"
            outlined
            block
            class="my-btn red-btn"
            @click="$emit('delete-apiary')"
          >
            {{$t("delete")}}
            <v-spacer></v-spacer>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm>
          <v-btn
            height="54px"
            depressed
            block
            dark
            class="my-btn save-btn"
            @click="saveApiaryAction"
            :disabled="!isEditApiaryValid"
          >
            {{$t("save")}}
            <v-spacer></v-spacer>
            <v-icon>{{check_icon}}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'EditApiaryCard',
    props: {
      center: {
        type: Object,
        default(rawProps) {
          return {
            lat: 38.2749497,
            lng: 23.8102717
          }
        }
      }
    },
    computed: {
      ...mapGetters([
        "selectedApiary",
        "inventory",
        "editApiary"
      ]),
      activeApiary: {
        get() {
          return this.selectedApiary.active;
        },
        set(value) {
          let payload = {
            apiaryId: this.selectedApiary.id,
            active: value
          }
          this.$store.dispatch('toggleApiaryActive', payload).then(() => {
            let selectedApiary = this.inventory.find(
              item => item.id == this.selectedApiary.id
            );
            this.$store.dispatch('updateSelectedApiary', selectedApiary).then(() => {
              let actionText = value ? 'Activate apiary' : 'Deactivate apiary';
              this.$gtag.event(
                actionText,
                {
                  'event_category': 'Apiaries'
                }
              );
            });
          });
        }
      },
      name: {
        get () {
          return this.$store.state.inventory.editApiary.name;
        },
        set(value) {
          this.$store.commit('setEditApiaryProp', {prop: 'name', value: value});
        }
      },
      description: {
        get () {
          return this.$store.state.inventory.editApiary.description;
        },
        set(value) {
          this.$store.commit('setEditApiaryProp', {prop: 'description', value: value});
        }
      },
      apiaryFunction: {
        get () {
          return this.$store.state.inventory.editApiary.apiaryFunction;
        },
        set(value) {
          this.$store.commit('setEditApiaryProp', {prop: 'apiaryFunction', value: value});
        }
      },
      isEditApiaryValid() {
        return this.editApiary.name != '' &&
          this.editApiary.name != null &&
          this.editApiary.description != '' &&
          this.editApiary.description != null
      }
    },
    data: () => ({
      zoom: 12,
      mapType: "terrain",
      mapOptions: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUI: false,
      },
      // center: {
      //   lat: 38.2749497,
      //   lng: 23.8102717
      // },
      showInfoWindow: false,
      search_icon: "$vuetify.icons.search_icon",
      check_icon: "$vuetify.icons.check_icon",
    }),
    // mounted() {
    //   this.center = {
    //     lat: this.editApiary.lat,
    //     lng: this.editApiary.lng
    //   }
    // },
    methods: {
      ...mapActions([
        "toggleApiaryActive",
        "setEditApiaryProp"
      ]),
      saveApiaryAction() {
        this.$emit('save', this.editApiary);
      },
      doGeocode(address) {
        this.address = true;

        let coordinates;

        this.$gmapApiPromiseLazy().then(() => {
          const geocoder = new google.maps.Geocoder();

          geocoder.geocode(
            {
              address: address,
              componentRestrictions: {
                country: "Greece",
              },
            },
            function (results, status) {
              if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                address = results[0].formatted_address;
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();
                this.setEditApiaryProp({prop: 'description', value: address});
                this.setEditApiaryProp({prop: 'lat', value: latitude});
                this.setEditApiaryProp({prop: 'lng', value: longitude});
                // this.center = { lat: latitude, lng: longitude };
              } else {
                this.setEditApiaryProp({prop: 'description', value: address});
                this.setEditApiaryProp({prop: 'lat', value: 38.2749497});
                this.setEditApiaryProp({prop: 'lng', value: 23.8102717});
                // this.center = { lat: 38.2749497, lng: 23.8102717 };
                return;
              }
            }.bind(this)
          );
        });
      },
      updateCoordinates(location) {
        let lat = location.latLng.lat();
        let lng = location.latLng.lng();
        this.setEditApiaryProp({prop: 'lat', value: lat});
        this.setEditApiaryProp({prop: 'lng', value: lng});

        this.$gmapApiPromiseLazy().then(() => {
          const geocoder = new google.maps.Geocoder();

          // Geocode the address
          geocoder.geocode(
            {
              location: { lat: this.editApiary.lat, lng: this.editApiary.lng },
            },
            function (results, status) {
              if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                this.setEditApiaryProp({prop: 'description', value: results[0].formatted_address});
              } else {
                return;
              }
            }.bind(this)
          );
        });
      }
    }
  }
</script>

<style type="text/css">
  #edit-apiary-card {
    padding-top: 40px;
    position: relative;
  }

  #edit-apiary-card.my-card .my-select-box {
    padding: 0;
  }

  #edit-apiary-card.my-card .my-select-box .v-label,
  #edit-apiary-card.my-card .my-select-box input,
  #edit-apiary-card.my-card .my-text-field .v-label,
  #edit-apiary-card.my-card .my-text-field input {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.75px;
  }

  #edit-apiary-card .my-btn.save-btn.v-btn--disabled,
  #edit-apiary-card .my-btn.save-btn.v-btn--disabled .v-icon {
    color: #03CFBB !important;
  }

  .apiary-list-item {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #262338;
  }

  #edit-location-container .my-text-field .v-input__icon {
    margin: 0 10px 0 10px;
  }

  @media screen and (max-width: 600px) {
    #edit-apiary-modal.dialog-container-center {
      width: 100%;
      left: 0;
      padding: 0;
    }

    #edit-apiary-card.my-card {
      padding: 40px 30px 70px;
      border-radius: 0;
    }
  }
</style>
