<template>
  <div id="apiaries-card">
    <v-card flat class="my-card" id="apiaries-card">
      <v-row>
        <v-col cols="12" class="d-flex align-center">
          <div class="mr-1">
            <v-img size="24" src="@/assets/flag.svg"></v-img>
          </div>
          <h2>{{$t('apiaries2')}}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-list id="apiaries-list">
            <v-list-item-group
              v-model="selected_apiary"
              active-class="active-apiary"
            >
              <v-list-item
                v-for="(item,index) in inventory"
                :key="'apiary-'+index"
                @click="$emit('view-apiary', {lat: item.lat, lng: item.lng})"
              >
                <v-list-item-icon>
                  <div class="pt-1 apiaries-switch-container">
                    <label class="switch">
                      <input
                        type="checkbox"
                        :checked="item.active"
                        @change="toggleApiaryActiveAction(item)"
                      >
                      <span class="slider round"></span>
                    </label>
                    <label class="switch-label">
                      {{$t("active2")}}
                    </label>
                  </div>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    <div class="apiary-name">{{item.name}}</div>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-content>
                  <div class="d-flex justify-end align-center">
                    <div class="apiary-icon">
                      <v-icon color="#000000de">
                        mdi-archive-outline
                      </v-icon>
                    </div>
                    <div class="my-title">{{item.hiveCount}}</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="4" offset="4">
          <v-btn
            text
            class="add-apiary-btn"
            @click="$emit('open-add-apiary-modal')"
          >
            {{$t('add_apiary')}}
            <v-icon color="#14142B">mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-overlay :value="showLoader">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import moment from 'moment';

  export default {
    name: 'ApiariesCard',
    computed: {
      ...mapGetters([
        "inventory",
        "inspectionDates",
        "selectedApiary",
        "editApiary"
      ]),
      selected_apiary: {
        get() {
          let apiaryIndex = null;
          if (this.editApiary != null) {
            apiaryIndex = this.inventory.findIndex(object => {
              return object.id == this.editApiary.id;
            });
          }
          return apiaryIndex;
        },
        set(value) {
          let apiaryObj = this.inventory[value];
          this.$store.dispatch("setEditApiary", apiaryObj);
        }
      }
    },
    data: () => ({
      showLoader: false
    }),
    methods: {
      ...mapActions([
        "resetAllHives",
        "getInspectionDates",
        "setLatestInspectionDate",
        "setSelectedInspectionDate",
        "getApiaryHives",
        "updateSelectedApiary",
        "toggleApiaryActive"
      ]),
      toggleApiaryActiveAction(item) {
        let active = item.active ? false : true;
        let payload = {
          apiaryId: item.id,
          active: active
        }
        this.toggleApiaryActive(payload).then(() => {
          let actionText = active ? 'Activate apiary' : 'Deactivate apiary';
          this.$gtag.event(
            actionText,
            {
              'event_category': 'Apiaries'
            }
          );
        });
      }
    }
  }
</script>

<style type="text/css">
  #apiaries-card .switch-label {
    font-size: 11px;
    text-transform: lowercase;
    padding: 0;
  }

  .apiaries-switch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .apiary-name {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: rgba(0,0,0,0.87);
  }

  #apiaries-card .my-title {
    min-width: 30px;
    text-align: right;
  }

  .apiary-icon {
    margin-right: 8px;
  }

  #apiaries-list .v-list-item__icon {
    margin-top: 0;
    margin-bottom: 0;
    padding: 12px 0;
  }

  .active-apiary {
    border-bottom: 3px solid #EAAC30;
  }
</style>